import React, { useState, useEffect } from "react";
import { Button, Form, Input } from "antd";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import LoginPic from "../assets/images/finekralogo.png";
import LoginHisarPic from "../assets/images/hisar.png";
import RequestHandler from "util/RequestHandler";

import { useDispatch, useSelector } from "react-redux";
import { userSignIn } from "appRedux/actions/Auth";
import { Link } from "react-router-dom";

const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => {
    return auth.authUser;
  });

  const [loading, setLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState();
  const [isWithoutLoginPayment, setIsWithoutLoginPayment] = useState(false);

  const onFinish = async (values) => {
    const domainUrl = localStorage.getItem("domainUrl");
    if (domainUrl) {
      values.b2BDomainUrl = domainUrl;
    }

    const response = await dispatch(userSignIn(values));
    if (!response?.success) {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => setLoading(false);

  useEffect(() => {
    let basePath = window.location.href.split("/signin")[0];
    let newLogoUrl = "";
    RequestHandler.get(`/FirmAuth/GetLoginEndpoints`).then((response) => {
      response.domain.map((item) => {
        if (basePath == item.endpoint) {
          newLogoUrl = item.logoUrl;
          if (item.tenantPaymentGuid !== "") {
            setIsWithoutLoginPayment(true);
          }

          localStorage.setItem("domainUrl", basePath);
          localStorage.setItem("isTenantSelectedFromModal", item.isTenantSelectedFromModal);
        }
      });
      if (newLogoUrl == "") {
        setLogoUrl(null);
      } else {
        setLogoUrl(newLogoUrl);
      }
    });
    if (token !== null) {
      props.history.push("/home");
    }
  }, [token, props.history]);

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-login-header gx-text-center">
          <h1 className="gx-login-title">
            {logoUrl === undefined ? null : (
              <img
                src={logoUrl === null ? LoginPic : logoUrl}
                alt="paratic"
                title="paratic"
                style={{ marginBottom: "11%", width: "64%" }}
              />
            )}
          </h1>
        </div>
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <Form.Item
            rules={[{ required: true, message: "Email alanı zorunludur!" }]}
            name="email"
          >
            <Input
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Şifre alanı zorunludur!" }]}
            name="password"
          >
            <Input
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Şifre"
            />
          </Form.Item>
          <Link className="gx-login-form-forgot" to="/forget-password">
            Şifremi Unuttum
          </Link>
          <Form.Item className="gx-text-center">
            <Button
              onClick={() => setLoading(true)}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Giriş Yap
            </Button>
          </Form.Item>
          {isWithoutLoginPayment ? (
            <Form.Item
              className="gx-text-center"
              style={{
                textAlignLast: "right",
                marginTop: -20,
                marginBottom: -10,
              }}
            >
              <Link className="gx-login-form-forgot" to="/pay-without-login">
                Üyeliksiz Ödeme
              </Link>
            </Form.Item>
          ) : null}
        </Form>
      </div>
    </div>
  );
};

export default SignIn;
