import React, { useEffect, useState } from "react";
import { ColumnHelper } from "util/TableHelper";
import {
  Empty,
  Col,
  Row,
  Card,
  List,
  Radio,
  Form,
  Tooltip,
  Collapse,
} from "antd";
import CardLogo from "./CardLogo";
import moment from "moment";
import B2BFileModal from "./B2BFileModal";
import { useTranslation } from "react-i18next";

const PosSelectableInstallmentTable = (props) => {
  const { useCommissionsForAvailableInstallment } = props.data;
  const [selectedValue, setSelectedValue] = useState(null);
  const { t } = useTranslation();

  const calculatePayableAmount = (
    amount,
    commissionRate,
    bankId,
    isCommissionFromDealer
  ) => {
    let payableAmount = amount;
    if (useCommissionsForAvailableInstallment) {
      payableAmount = amount + (amount / 100) * commissionRate;
    }

    if (isCommissionFromDealer && useCommissionsForAvailableInstallment) {
      payableAmount = (amount / (100 - commissionRate)) * 100;
    }

    if (bankId == 856) {
      payableAmount = amount / ((100 - commissionRate) / 100);
    }
    return payableAmount;
  };

  const onChangeRadio = (e) => {
    if (props.onChange) {
      props.onChange(e.target.value, {
        bankId: e.target.bankId,
        installmentCount: e.target.installmentCount,
        cardType: e.target.cardType,
      });
    }

    setSelectedValue(e.target.value);
  };

  const renderList = (commissions, justOneInstallment = false) => {
    let rates = Object.keys(commissions).filter(
      (x) => x.startsWith("commissionRate") && !x.endsWith("Enabled")
    );

    return (
      <List size="small">
        {rates.map((rate, i) => {
          const commissionRate = commissions[rate];
          if (!commissionRate && commissionRate !== 0) return null;
          if (justOneInstallment && i !== 0) return null;
          if (!justOneInstallment && i === 0) return null;

          const totalInstallment =
            i === 0 ? 1 : i + 1 + commissions.extraInstallmentCount;
          const payableAmount = calculatePayableAmount(
            props.amount ?? 0,
            commissionRate,
            commissions.bankId,
            commissions.isCommissionFromDealer
          );
          const installmentAmount = payableAmount / totalInstallment;

          const extra =
            commissions.extraInstallmentCount > 0 ? (
              <span className="gx-text-success">{`+(${commissions.extraInstallmentCount})`}</span>
            ) : undefined;

          return (
            <List.Item key={i}>
              <Radio
                value={`${commissions.bankId}/${commissions.id}/${
                  commissions.cardName
                }/${i + 1}`}
                bankId={commissions.bankId}
                installmentCount={i + 1}
                style={{ width: "100%" }}
                commissionDefinitionId={commissions.id}
                cardType={commissions.cardName}
                disabled={
                  props.disabledOptions != undefined &&
                  props?.disabledOptions === true
                }
              >
                <span>
                  {i + 1} {extra} x{" "}
                  {ColumnHelper.renderDecimal(installmentAmount)}
                </span>

                {selectedValue ===
                  `${commissions.bankId}/${commissions.id}/${
                    commissions.cardName
                  }/${i + 1}` && (
                  <span style={{ fontSize: "small", display: "block" }}>
                    {ColumnHelper.renderDecimal(payableAmount)}{" "}
                    {props.amount > 0
                      ? `(% ${ColumnHelper.renderDecimal(
                          100 * ((payableAmount - props.amount) / props.amount)
                        )})`
                      : undefined}{" "}
                  </span>
                )}
              </Radio>
            </List.Item>
          );
        })}

        {commissions.description && !justOneInstallment && (
          <List.Item key={-2}>{commissions.description}</List.Item>
        )}
      </List>
    );
  };

  const renderKGT = (commissions) => {
    if (commissions.extraInstallmentExpireDate) {
      return (
        <div style={{ textAlign: "center", margin: "10px 0" }}>
          <Tooltip title="Kampanya Geçerlilik Tarihi">
            K.G.T {moment(commissions.extraInstallmentExpireDate).format("L")}
          </Tooltip>
        </div>
      );
    }
  };

  return props.data?.commissions?.length > 0 ? (
    <Form.Item
      name="bankIdInstallment"
      label={
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "10px" }}>{t("paymentOptions")} :</span>
          <B2BFileModal
            filePath={props.filePath}
            visibility={props.campaignButtonVisibility}
          />
        </div>
      }
      colon={false}
      labelCol={{ span: 24, style: { height: "66px" } }}
      rules={[
        {
          required:
            props.disabledOptions != undefined &&
            props?.disabledOptions === true
              ? false
              : true,
          message: t("paymentOptionsRequiredMessage"),
        },
      ]}
    >
      <Radio.Group
        onChange={onChangeRadio}
        value={props.radioValue}
        style={{ width: "100%" }}
      >
        <Collapse accordion={true} defaultActiveKey={["1"]}>
          <Collapse.Panel
            header={t("singlePayment")}
            key="1"
            style={{ border: "none" }}
          >
            <Row gutter={[16, 16]} justify="center" title={t("singlePayment")}>
              {props.data?.commissions
                ?.filter(
                  (commission) =>
                    ![1].every(
                      (num) => commission[`commissionRate${num}`] == null
                    )
                )
                .map((commissions) => (
                  <Col xs={24} sm={12} md={8} lg={6} key={commissions.id}>
                    <Card
                      style={{ margin: "10px 0", height: "calc(100% - 20px)" }}
                      className="gx-card"
                      bodyStyle={{ padding: 0 }}
                    >
                      <CardLogo
                        {...commissions}
                        rowStyle={{ marginTop: 5, marginBottom: 5 }}
                        imageHeight={30}
                        allList={props.data?.commissions?.filter(
                          (x) => !x.isVisaMasterCard
                        )}
                      />
                      {renderList(commissions, true)}
                      {/* {renderKGT(commissions)} */}
                    </Card>
                  </Col>
                ))}
            </Row>
          </Collapse.Panel>
        </Collapse>
        <Collapse accordion={true} defaultActiveKey={["2"]}>
          <Collapse.Panel
            header={
              <span style={{ color: "red" }}>{t("installmentOptions")}</span>
            }
            key="2"
          >
            <Row gutter={[16, 16]} justify="center">
              {props.data?.commissions
                ?.filter(
                  (commission) =>
                    ![2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].every(
                      (num) => commission[`commissionRate${num}`] == null
                    )
                )
                .map((commissions) => (
                  <Col xs={24} sm={12} md={8} lg={6} key={commissions.id}>
                    <Card
                      style={{ margin: "10px 0", height: "calc(100% - 20px)" }}
                      className="gx-card"
                      bodyStyle={{ padding: 0 }}
                    >
                      <CardLogo
                        {...commissions}
                        rowStyle={{ marginTop: 5, marginBottom: 5 }}
                        imageHeight={30}
                        allList={props.data?.commissions?.filter(
                          (x) => !x.isVisaMasterCard
                        )}
                      />
                      {renderList(commissions, false)}
                      {renderKGT(commissions)}
                    </Card>
                  </Col>
                ))}
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Radio.Group>
    </Form.Item>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="Ödeme seçenekleri için kart bilgilerinizi giriniz."
    />
  );
};

export default PosSelectableInstallmentTable;
