import React, { useState, useEffect } from "react";
import { Card, Layout, Modal, Select } from "antd";
import { Link } from "react-router-dom";

import { toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
import { changeTenant } from "appRedux/actions/Auth";
import UserInfo from "components/UserInfo";
import Auxiliary from "util/Auxiliary";
import RequestHandler from "util/RequestHandler";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";

const { Header } = Layout;

const Topbar = () => {
  const { navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const [tenantSelect, setTenantSelect] = useState({
    data: [],
    loading: false,
  });
  const [activeTenantId, setActiveTenantId] = useState();
  const [isTenantSelectedFromModal, setIsTenantSelectedFromModal] = useState(false);

  const [activeTenant, setActiveTenant] = useState();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getTenants();
  }, []);

  const getTenants = () => {

    setTenantSelect({
      ...tenantSelect,
      loading: true,
    });
    RequestHandler.get(`/FirmAuth/GetTenants`).then((response) => {
      setTenantSelect({
        data: response,
        loading: false,
      });


      const tenantId = localStorage.getItem("tenantId");
      const isTenantSelectedFromModal = localStorage.getItem("isTenantSelectedFromModal") == 'true';

      const modalStatus = localStorage.getItem("modal");

      setActiveTenantId(tenantId);

      setActiveTenant(response.find(y => y.id == tenantId));
      setIsTenantSelectedFromModal(isTenantSelectedFromModal);

      if(modalStatus != null) {    
        setShowModal(modalStatus == 'true');
        localStorage.removeItem("modal");
      }
      else {
        setShowModal(isTenantSelectedFromModal);
      }

    });
  };

  const onSelectTenant = async (tenantId) => {
    if (activeTenantId === tenantId) return;

    const response = await dispatch(changeTenant(tenantId));

    if (response?.success) {
      setActiveTenantId(tenantId);

      if(isTenantSelectedFromModal) {

        localStorage.setItem("modal", false);

      }
    }
  };


  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      {/* <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="Paratic" src={require("assets/images/w-logo.png")} />
      </Link> */}

      <ul className="gx-header-notifications gx-ml-auto">
        { 
            isTenantSelectedFromModal ? <li onClick={() => setShowModal(true)} style={{ cursor: "pointer"}}> {activeTenant?.name} </li> :  
            <li className="gx-language">
            <Select
              loading={tenantSelect.loading}
              value={activeTenantId}
              onSelect={onSelectTenant}
              style={{ maxWidth: 300 }}
            >
              {tenantSelect.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </li>

        }
       
        {width >= TAB_SIZE ? null : (
          <Auxiliary>
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          </Auxiliary>
        )}
      </ul>


      <Modal visible={showModal} footer={null} title="Şirket Listesi" onCancel={() => setShowModal(false)}>
        <div>
         {tenantSelect.data.map((item) => (
          <Card  key={item.id} data-value={item.id} onClick={() =>  onSelectTenant(item.id) }  className={activeTenantId == item.id ? "selected" : ""} hoverable={true}>
            {item.name}
            </Card>
          ))}
         </div>
      
       </Modal>
    </Header>
  );
};

export default Topbar;
