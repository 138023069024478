import { Modal, Button, Upload } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const B2BFileModal = ({ filePath, visibility }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const imageRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (filePath) {
      const img = new Image();
      img.src = filePath;
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
      };
    }
  }, [filePath]);
  const handleCancel = () => {
    URL.revokeObjectURL(filePath);
    setModalVisible(false);
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }} hidden={!visibility}>
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true);
        }}
        style={{ marginLeft: 10, marginTop: 20 }}
        hidden={filePath === null || filePath === undefined} // İstenirse butonlar arasına boşluk ekleyebilirsiniz
      >
        {t("showCampaign")}
      </Button>

      <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
        style={{ maxWidth: "100%" }}
        width={imageDimensions.width}
        bodyStyle={{ maxHeight: imageDimensions.height }}
      >
        <img
          src={filePath}
          alt="Dosya Resmi"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </Modal>
    </div>
  );
};

export default B2BFileModal;
