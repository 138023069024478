import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "antd";
import { ColumnHelper } from "../../util/TableHelper";
import { useTranslation } from "react-i18next";

const PosCollectionAmountSummary = (props) => {
  const { t } = useTranslation();

  return (
    <Row justify="center">
      <Col xs={24} lg={props.colSpan != undefined ? props.colSpan : 8}></Col>

      <Col xs={24} lg={4} style={{ textAlign: "right" }}>
        <div style={{ marginBottom: 10 }}>
          <p>
            {t("amount")}:{" "}
            {props.activeAmount > 0
              ? `${ColumnHelper.renderDecimal(props.activeAmount)} `
              : undefined}{" "}
          </p>
          <p>
            {t("commission")}:{" "}
            {props.activeAmount > 0 && props.payableAmount > 0
              ? `${ColumnHelper.renderDecimal(
                  props.payableAmount - props.activeAmount
                )} `
              : undefined}{" "}
          </p>
          <p>
            {t("commissionRate")}:{" "}
            {props.activeAmount > 0 && props.payableAmount > 0
              ? `% ${ColumnHelper.renderDecimal(
                  100 *
                    ((props.payableAmount - props.activeAmount) /
                      props.activeAmount)
                )} `
              : undefined}{" "}
          </p>
          <p>
            {t("totalAmount")}:{" "}
            {props.payableAmount > 0
              ? `${ColumnHelper.renderDecimal(props.payableAmount)} `
              : undefined}{" "}
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default PosCollectionAmountSummary;
